let oldApi = 'http://zeutec.selfhost.eu/zeutec_restApi/'
let liveApi = 'https://api.spectraalyzer.com/'
let testApi = 'https://testing-api.spectraalyzer.com/'
let localApi = 'http://localhost:8000/'

const zeutecConfig = {
    basename: '/',
    defaultPath: '/dashboard/default',
    fontFamily: `'lato', lato`,
    borderRadius: 12,
    apiUrl: testApi,
    MapboxToken: "pk.eyJ1IjoidmFtc2hpMDYiLCJhIjoiY2x2cDFqOTh3MHVxZDJscDF4bTl3aDV4YSJ9._zcXF4_wngoPvvkEr--2tA"
};

export default zeutecConfig;